export const API_HOST = 'https://lissok.space';
///api/channel/batch/api/channel/batchexport const API_HOST = 'http://localhost:8000/sales-bot';
///export const API_HOST = 'http://localhost:8000';

export const getApiUrl = (endpoint) => `${API_HOST}${endpoint}`;

const createAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return token ? { 'Authorization': `Bearer ${token}` } : {};
};

const fetchWithAuth = (url, options = {}) => {
    const headers = {
        ...options.headers,
        ...createAuthHeaders(),
    };

    return fetch(url, { ...options, headers })
        .then(response => {
            if (response.status === 401) {
                localStorage.removeItem('token');
                window.location.href = '/login';
                throw new Error('Unauthorized');
            }
            return response;
        });
};

export const categoryService = {
    getCategories: () => fetchWithAuth(getApiUrl('/api/category/nested')),
    searchCategoryByQuery: (query) => fetchWithAuth(getApiUrl(`/api/category/autocomplete/${query}`)),
    getCategoryById: (id) => fetchWithAuth(getApiUrl(`/api/category/${id}`)),
};

export const channelService = {
    getChannels: (page = 1, perPage = 10) =>
        fetchWithAuth(getApiUrl(`/api/channel?page=${page}&per_page=${perPage}&field_sorted=id&type_sorted=asc`)),
    getChannelById: (id) => fetchWithAuth(getApiUrl(`/api/channel/${id}`)),
    getChannelByTelegramId: (tgId) => fetchWithAuth(getApiUrl(`/api/channel/tg/${tgId}`)),
    createChannel: (data) => fetchWithAuth(getApiUrl('/api/channel'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    updateChannel: (id, data) => fetchWithAuth(getApiUrl(`/api/channel/${id}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    deleteChannel: (id) => fetchWithAuth(getApiUrl(`/api/channel/${id}`), {
        method: 'DELETE',
    }),
};

export const parsingConfigService = {
    getConfigs: (page = 1, perPage = 10, includeProductCount = false) =>
        fetchWithAuth(getApiUrl(`/api/parsing_config?page=${page}&per_page=${perPage}&field_sorted=id&type_sorted=asc&include_product_count=${includeProductCount}`)),
    createConfig: (data) => fetchWithAuth(getApiUrl('/api/parsing_config'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    updateConfig: (id, data) => fetchWithAuth(getApiUrl(`/api/parsing_config/${id}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    deleteConfig: (id) => fetchWithAuth(getApiUrl(`/api/parsing_config/${id}`), {
        method: 'DELETE',
    }),
    searchConfigs: (query, limit = 10, includeProductCount = false) =>
        fetchWithAuth(getApiUrl(`/api/parsing_config/autocomplete?q=${encodeURIComponent(query)}&limit=${limit}&include_product_count=${includeProductCount}`)),

};

export const promoCodeService = {
    getCodes: (page = 1, perPage = 10) =>
        fetchWithAuth(getApiUrl(`/api/promo-code?page=${page}&per_page=${perPage}&field_sorted=id&type_sorted=asc`)),
    createCode: (data) => fetchWithAuth(getApiUrl('/api/promo-code'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    updateCode: (id, data) => fetchWithAuth(getApiUrl(`/api/promo-code/${id}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    deleteCode: (id) => fetchWithAuth(getApiUrl(`/api/promo-code/${id}`), {
        method: 'DELETE',
    }),
};

export const subscriptionPlanService = {
    getPlans: (page = 1, perPage = 10) =>
        fetchWithAuth(getApiUrl(`/api/subscription-plan?page=${page}&per_page=${perPage}&field_sorted=id&type_sorted=asc`)),
    createPlan: (data) => fetchWithAuth(getApiUrl('/api/subscription-plan'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    updatePlan: (id, data) => fetchWithAuth(getApiUrl(`/api/subscription-plan/${id}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    deletePlan: (id) => fetchWithAuth(getApiUrl(`/api/subscription-plan/${id}`), {
        method: 'DELETE',
    }),
};

export const textService = {
    getTexts: (page = 1, perPage = 10) =>
        fetchWithAuth(getApiUrl(`/api/text?page=${page}&per_page=${perPage}&field_sorted=id&type_sorted=asc`)),
    createText: (data) => fetchWithAuth(getApiUrl('/api/text'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    updateText: (id, data) => fetchWithAuth(getApiUrl(`/api/text/${id}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    deleteText: (id) => fetchWithAuth(getApiUrl(`/api/text/${id}`), {
        method: 'DELETE',
    }),
};

export const broadcastService = {
    getBroadcasts: (page = 1, perPage = 10) =>
        fetchWithAuth(getApiUrl(`/api/broadcast?page=${page}&per_page=${perPage}&field_sorted=id&type_sorted=asc`)),
    updateBroadcast: (id, data) => fetchWithAuth(getApiUrl(`/api/broadcast/${id}`), {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }),
    deleteBroadcast: (id) => fetchWithAuth(getApiUrl(`/api/broadcast/${id}`), {
        method: 'DELETE',
    }),
};

export const analyticsService = {
    getAnalytics: (timeframe = 'week') =>
        fetchWithAuth(getApiUrl(`/api/user/analytics?timeframe=${timeframe}`)),
};

export const settingsService = {
    getReferralBonusDays: () =>
        fetchWithAuth(getApiUrl('/api/settings/referral-bonus-days')),
    updateReferralBonusDays: (days) =>
        fetchWithAuth(getApiUrl('/api/settings/referral-bonus-days'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ days }),
        }),
};