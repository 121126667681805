import React from 'react';
import { FaTimes, FaPen } from "react-icons/fa";
import DataTable from './common/DataTable';
import { categoryService } from '../config/api';

const CategoryTable = ({
    tableData,
    totalItems,
    currentPage,
    totalPages,
    onEdit,
    onDelete,
    onPageChange,
    pageSize = 10
}) => {
    const ExpandableCell = React.memo(({ categoryId, shortPath }) => {
        const [isExpanded, setIsExpanded] = React.useState(false);
        const [fullPath, setFullPath] = React.useState(null);
        const [isLoading, setIsLoading] = React.useState(false);

        const handleClick = async () => {
            if (!isExpanded && !fullPath && !isLoading) {
                setIsLoading(true);
                try {
                    const response = await categoryService.getCategoryById(categoryId);
                    if (response.ok) {
                        const data = await response.json();
                        setFullPath(data.full_path);
                    }
                } catch (error) {
                    console.error('Error fetching category path:', error);
                } finally {
                    setIsLoading(false);
                }
            }
            setIsExpanded(!isExpanded);
        };

        return (
            <div
                className="expandable-cell"
                onClick={handleClick}
            >
                {isExpanded ? (isLoading ? 'Загрузка...' : (fullPath || shortPath)) : shortPath}
            </div>
        );
    });

    const columns = [
        {
            field: 'categoryName',
            header: 'Категория',
            render: (item) => (
                <ExpandableCell
                    categoryId={item.category_id}
                    shortPath={item.category?.name || 'Не задано'}
                />
            )
        },
        {
            field: 'telegram_id',
            header: 'Телеграм',
            render: (item) => (
                <div
                    className="cell-content"
                    data-tooltip={item.channelName || "Не задано"}
                >
                    {item.channelName || "Не задано"}
                </div>
            )
        },
        {
            field: 'min_discount',
            header: (
                <div className="table-header-tooltip" title="Минимальная скидка в процентах">
                    Скидка, %
                </div>
            )
        },
        {
            field: 'min_price',
            header: (
                <div className="table-header-tooltip" title="Минимальная цена товара в рублях">
                    Цена от, ₽
                </div>
            )
        },
        {
            field: 'max_price',
            header: (
                <div className="table-header-tooltip" title="Максимальная цена товара в рублях">
                    Цена до, ₽
                </div>
            )
        },
        {
            field: 'min_rating',
            header: (
                <div className="table-header-tooltip" title="Минимальный рейтинг товара (от 1 до 5)">
                    Рейтинг
                </div>
            )
        },
        {
            field: 'page_range',
            header: (
                <div className="table-header-tooltip" title="Количество страниц для сканирования">
                    Страниц
                </div>
            )
        },
        {
            field: 'excluded_brands',
            header: 'Исключенные бренды',
            render: (item) => (
                <div
                    className="cell-content"
                    data-tooltip={item.excluded_brands?.join(", ") || "Не заданы"}
                >
                    {item.excluded_brands?.join(", ") || "Не заданы"}
                </div>
            )
        },
        {
            field: 'excluded_product_names',
            header: 'Исключенные товары',
            render: (item) => (
                <div
                    className="cell-content"
                    data-tooltip={item.excluded_product_names?.join(", ") || "Не заданы"}
                >
                    {item.excluded_product_names?.join(", ") || "Не заданы"}
                </div>
            )
        },
        {
            field: 'is_active',
            header: 'Включено',
            render: (item) => item.is_active ? "Да" : "Нет"
        },
        {
            field: 'product_count',
            header: (
                <div className="table-header-tooltip" title="Количество товаров в категории">
                    Товаров
                </div>
            ),
            render: (item) => item.product_count?.toLocaleString() || '-',
            hidden: !tableData.some(item => 'product_count' in item)
        },
        {
            field: 'actions',
            sortable: false,
            header: 'Действия',
            render: (item) => (
                <div className="action-btns">
                    <button
                        onClick={() => onEdit(item)}
                        className="btn btn-warning btn-sm"
                    >
                        <FaPen size={16} />
                    </button>
                    <button
                        onClick={() => onDelete(item.id)}
                        className="btn btn-danger btn-sm"
                    >
                        <FaTimes size={16} />
                    </button>
                </div>
            )
        }
    ].filter(column => !column.hidden);

    return (
        <div className="col-12 col-lg-8">
            <DataTable
                data={tableData}
                columns={columns}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={onPageChange}
                title={
                    <div>
                        Категории
                        <small className="text-muted ms-2" style={{ fontSize: '0.8em' }}>
                            (нажмите на название категории, чтобы увидеть полный путь)
                        </small>
                    </div>
                }
            />
        </div>
    );
};

export default CategoryTable;